import { ExtendedThemeOptions } from '../theme'
import ThriveLogoSingleLeafOnDark from './themeLogos/ThriveLogoSingleLeafOnDark'
import { darkShadows } from '../shadows'

export const darkTheme: ExtendedThemeOptions = {
    Logo: ThriveLogoSingleLeafOnDark,
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
                }
            }
        }
    },
    palette: {
        primary: {
            main: '#4B3378',
            dark: '#1E0056',
            light: '#A599BB',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#5DB4BC',
            dark: '#145A6A',
            light: '#B0D5DE',
            contrastText: '#FFFFFF'
        },
        accent: {
            main: '#5DB4BC',
            contrastText: '#FFFFFF'
        },
        mode: 'dark',
        background: {
            default: '#171c24',
            paper: '#222b36'
        },
        divider: 'rgba(145, 158, 171, 0.24)',
        text: {
            primary: '#ffffff',
            secondary: '#F7F7F8'
        },
        info: {
            main: '#5DB4BC',
            dark: '#145A6A',
            light: '#9DCCD7',
            contrastText: '#FFFFFF'
        },
        error: {
            main: '#C54160',
            dark: '#90253F',
            light: '#E793A2',
            contrastText: '#ffffff'
        },
        warning: {
            main: '#C7B4A7',
            dark: '#AA9587',
            light: '#E2D0C3',
            contrastText: '#000000de'
        },
        success: {
            main: '#5DB4BC',
            dark: '#145A6A',
            light: '#9DCCD7',
            contrastText: '#ffffff'
        },
        grey: {
            50: '#172235',
            100: '#343E4E',
            200: '#4F5865',
            300: '#868B95',
            400: '#868B95',
            500: '#A2A6AE',
            600: '#BFC2C7',
            700: '#DBDDE0',
            800: '#F7F7F8',
            900: '#FBFBFB'
        },
        journeys: {
            recharge: {
                main: '#BA8CFF',
                dark: '#9570CC',
                light: '#F0E6FE',
                contrastText: '#FFFFFF'
            },
            focus: {
                main: '#5DBFF0',
                dark: '#4A99C0',
                light: '#E6F5FE',
                contrastText: '#FFFFFF'
            },
            move: {
                main: '#F26B3D',
                dark: '#C25631',
                light: '#FEE6E6',
                contrastText: '#FFFFFF'
            },
            food: {
                main: '#FFB331',
                dark: '#CC8F27',
                light: '#FDF9E4',
                contrastText: '#FFFFFF'
            },
            connect: {
                main: '#FC72A5',
                dark: '#CA5B83',
                light: '#FEE6F9',
                contrastText: '#FFFFFF'
            },
            money: {
                main: '#60BF70',
                dark: '#56AC65',
                light: '#E6FEEB',
                contrastText: '#FFFFFF'
            },
            stressManagement: {
                main: '#39C6B8',
                dark: '#26A79A',
                light: '#EAFFFD',
                contrastText: '#FFFFFF'
            }
        }
    },
    gradient: {
        light: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 50%), linear-gradient(33.27deg, #EAE8FC 29.3%, rgba(241, 238, 233, 0.98) 83.39%);',
        main: 'linear-gradient(128.32deg, #FDFDFD 3.91%, #EBF3F4 94.19%)'
    },
    videoTextProtectionOverlay: '#000000BF',
    shadows: darkShadows
}
