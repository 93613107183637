import { Box, Dialog, DialogProps, DialogTitle, Stack } from '@mui/material'
import React from 'react'
import { useTheme } from '../../../../hooks'
import LeafCloseButton from '../../Buttons/LeafCloseButton'
import CoreTypography from '../../CoreTypography'

export interface LeafDialogProps extends DialogProps {
    hideCloseButton?: boolean
    dialogTitle?: React.ReactElement | string
    onClose?: () => void
}

export const LeafDialog: React.FC<LeafDialogProps> = ({
    hideCloseButton = false,
    dialogTitle,
    onClose,
    ...props
}) => {
    const theme = useTheme()

    const { sx, children, ...otherProps } = props

    return (
        <Dialog
            onClose={onClose}
            {...otherProps}
            sx={{
                ...sx,
                '.MuiDialog-paper': {
                    py: 1.5,
                    px: 3
                },
                '.MuiDialogTitle-root': {
                    py: 1.5,
                    px: 0
                },
                '.MuiDialogContent-root': {
                    py: 1.5,
                    px: 0
                },
                '.MuiDialogActions-root': {
                    py: 1.5,
                    px: 0,
                    justifyContent: 'flex-end',
                    [theme.breakpoints.down('sm')]: {
                        flexFlow: 'wrap',
                        '> :not(:first-of-type)': {
                            ml: 0,
                            mt: 2
                        },
                        '.MuiButton-root': {
                            width: '100%'
                        }
                    }
                }
            }}
        >
            <DialogTitle
                component="div"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <CoreTypography
                    variant="h3"
                    color="primary"
                    component="h2"
                    id={props['aria-labelledby']}
                >
                    {dialogTitle}
                </CoreTypography>

                {!hideCloseButton && (
                    <LeafCloseButton
                        onClick={() => onClose()}
                        sx={{ ml: 2, alignSelf: 'flex-start' }}
                    />
                )}
            </DialogTitle>

            {children}
        </Dialog>
    )
}
export default LeafDialog
